.question-page .ant-picker-input > input {
    color : #6897D0;
}

.question-page .ant-picker-input > input::placeholder{
    color : #6897D0;
}

.question-page .ant-picker-input > .ant-picker-suffix > span {
    color : #6897D0;
}

.question-page .ant-form-item {
    margin-bottom: 8px;
}
