.appointment-page .ant-form-item {
    margin-bottom: 8px;
}

.appointment-page .ant-select-selector {
    align-items: center;
}

.appointment-page .ant-select-selector,
.appointment-page .ant-select-selection-search-input {
    height: 100% !important;
}

.appointment-page .ant-form-item-has-error .ant-input,
.appointment-page .ant-form-item-has-error .ant-input-affix-wrapper,
.appointment-page .ant-form-item-has-error .ant-input:hover,
.appointment-page .ant-form-item-has-error .ant-input-affix-wrapper:hover {
    border-color: #ff4d4f !important;
}

.appointment-page .ant-input::placeholder,
.appointment-page .ant-select-selection-placeholder {
    color: #6897D0;
}

.appointment-page .ant-form-item-label>label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
    content: '' !important;
}
.card-style {
    text-align: -webkit-center;
}
.ant-card-head-title {
    color: #6B70C6;
}
