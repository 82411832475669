.register-page .ant-form-item {
    margin-bottom: 8px;
}

.register-page .ant-select-selector {
    align-items: center;
}

.register-page .ant-select-selector,
.register-page .ant-select-selection-search-input {
    height: 100% !important;
}

.register-page .ant-form-item-has-error .ant-input,
.register-page .ant-form-item-has-error .ant-input-affix-wrapper,
.register-page .ant-form-item-has-error .ant-input:hover,
.register-page .ant-form-item-has-error .ant-input-affix-wrapper:hover {
    border-color: #ff4d4f !important;
}

.register-page .ant-input::placeholder,
.register-page .ant-select-selection-placeholder {
    color: #6897D0;
}

.register-page .ant-form-item-label>label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
    content: '' !important;
}
.upload-list-inline .ant-upload-list-item {
    float: left;
    width: 200px;
    margin-right: 8px;
}
  
.upload-list-inline [class*='-upload-list-rtl'] .ant-upload-list-item {
    float: right;
}
.ant-upload-list-item {
    border-color: #17ff02 !important;
}

.ant-upload-list-item-error{
    border-color: #17ff02 ;
}

