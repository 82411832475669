.login-with-basic-page .ant-form-item {
    margin-bottom: 8px;
}

.login-with-basic-page .ant-form-item-has-error .ant-input,
.login-with-basic-page .ant-form-item-has-error .ant-input-affix-wrapper,
.login-with-basic-page .ant-form-item-has-error .ant-input:hover,
.login-with-basic-page .ant-form-item-has-error .ant-input-affix-wrapper:hover,
.login-with-basic-page .ant-form-item-has-error .ant-input::placeholder {
    border-color: #ff4d4f !important;
}

.login-with-basic-page .ant-input::placeholder {
    color: #6897D0;
}

.login-with-basic-page .ant-form-item-label>label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
    content: '' !important;
}
